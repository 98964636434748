import { Box, DialogActions, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material'
import Caller from 'Apis/config/Caller';
import { POST } from 'Apis/config/RequestType';
import { send_password_mail } from 'Apis/config/Url';
import { create_user_password } from 'Apis/config/Url';


import MDButton from 'components/MDButton';

import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';



const ResetPasswordLink = (props) => {
   
    const { enqueueSnackbar } = useSnackbar();
    const [values, setAllValues] = useState({
        email: "",
    });

    const [errors, setAllErrors] = useState({
        email: '',
    });

    const allTrue = () => {
        if (values.email) {
            return false;
        }
        return true;
    }
    const textUpdate = (e) => {


        setAllValues((state) => ({
            ...state,
            [`${e.target.name}`]: e.target.value
        }))

        setAllErrors((values) => ({
            email: ""
        }));
    }

    const addLookup = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await Caller(send_password_mail + "?email=" + values.email.trim(), "formData", POST, false, true).then((res) => {
            if (res.success) {
                enqueueSnackbar('Password reset send successfully, Please check your mail', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
                props.close()

            } else {
                setAllErrors((values) => ({
                    email: res.message
                }));
            }

        });
        event.preventDefault();
    }

    return (
        <Box
            component="form"
            onSubmit={addLookup}
            sx={{
                '#demo-row-radio-buttons-group-label': { fontSize: 'medium', textAlign: "left" },
                '.role-select-custom': { width: "100%" },
                '.add-label': { pb: 1 },
                '.radio-button-custom': { fontSize: 'small' },
            }}

            noValidate
        >

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12}>
                        <InputLabel className='add-label'>Email <sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextField
                            required
                            id="outlined-required"
                            name='email'
                            onChange={textUpdate}
                            style={{ width: "100%" }}
                            value={values.email}
                            error={!!errors.email}
                            helperText={errors.email === "" ? '' : errors.email}
                            InputProps={{
                                inputProps: {
                                    maxLength: 100, minLength: 1
                                }
                            }}
                        // onChange={textUpdate}
                        />
                    </Grid>



                    <DialogActions
                        sx={{
                            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                            marginTop: "10%",
                            marginBottom: "0%",
                            width: "104%",
                            borderRadius: '0.5rem',
                            transform: "translateY(35%)",
                            '.action-button': { width: 100 }
                        }}
                    >
                        <MDButton color='success' className={`action-button`} type="submit" disabled={allTrue()} >Send</MDButton>
                        <MDButton color='warning' className="action-button" onClick={props.close}>Cancel</MDButton>
                    </DialogActions>


                </Grid>
            </Grid>

        </Box>
    )

}


export default ResetPasswordLink