/* eslint-disable consistent-return */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE } from 'Apis/config/ResponseHandler';
// react-router-dom components
import { createSearchParams, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import logo from "assets/images/logo.png";
import fd_logo from "assets/images/logo.png";
import arp_logo from "assets/images/apnaresearch_logo.png";
import "./login.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Caller from 'Apis/config/Caller';
import { login } from "Apis/config/Url";
import { check_is_logged_in } from "Apis/config/Url";
import { DELETE, GET, POST } from "Apis/config/RequestType";
import Cookies from "js-cookie";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateLookup from "layouts/master-data/lookup/add-update-lookup";
import ResetPasswordLink from "../create-password/reset-password-link";
import { RIGHTS } from "Apis/config/ResponseHandler";
import { EMP_USER_ID } from "Apis/config/ResponseHandler";


function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [loginData, setLoginData] = useState();
  const location = useLocation();
  const [baseUrl, setBaseUrl] = useState('');
  // const [baseUrl, setBaseUrl] = useState('https://crm.apnaresearchplus.com/');
  // const [baseUrl, setBaseUrl] = useState('https://crm.firstdemat.com/');
  // console.log("Base URL = ", baseUrl);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();

  const [allValues, setAllValues] = useState({
    username: '',
    password: '',
    setOpen: false,
    openCreatePwd: false,
    openAlreadyLogin: false,

  });

  const [errors, setAllErrors] = useState({
    email: '',
    password: "",
  })

  const handleChange = (event) => {
    event.preventDefault();
    setAllErrors((values) => ({ password: "" }))

    setAllValues((values) => ({
      ...values,
      username: event.target.value,

    }));

  }

  const handleChange1 = (event) => {
    event.preventDefault();
    setAllErrors((values) => ({ password: "" }))

    setAllValues((values) => ({
      ...values,

      password: event.target.value
    }));
  }
  const openEmailInputPopup = (event) => {
    setAllValues((values) => ({ setOpen: true }))
  }


  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAllValues((values) => ({ setOpen: false, openCreatePwd: false }));
    }
    // location.reload();
  }

  const loginUser = async (event) => {
    // event.target.classList.add("was-validated");
    // event.preventDefault();
    // event.stopPropagation();
    const formData = new FormData();
    formData.append("username", allValues.username.trim());
    formData.append('password', allValues.password);
    const data = await Caller(login, formData, POST, false, true)
      .catch(error => { alert(JSON.stringify(error.message)); })


    if (data.success == true) {
      setLoginData(data);
      Cookies.remove(LOGIN_USERTYPE_COOKIE);
      Cookies.remove(LOGIN_AUTH_COOKIE);
      Cookies.remove(LOGIN_REFRESH_COOKIE);
      Cookies.remove(RIGHTS);
      Cookies.remove(EMP_USER_ID);

      Cookies.set(LOGIN_AUTH_COOKIE, data.data.token);
      Cookies.set(LOGIN_REFRESH_COOKIE, data.data.refreshToken);
      Cookies.set(RIGHTS, data.data.rights);
      Cookies.set(EMP_USER_ID, data.data.employee_user_id);
      Cookies.set(LOGIN_USERTYPE_COOKIE, data.data.user_type);

      if (data.data.is_password_set === false) {
        setAllValues((values) => ({
          ...values,
          openCreatePwd: true,
        }));
      } else {
        // Cookies.set(LOGIN_USERTYPE_COOKIE, res.userType);
        navigate("/dashboard", { state: { message: data } });
      }

    } else {
      setAllErrors((values) => ({ password: "Invalid Credentials." }))

    }

  };

  const checkLoggedIn = async (event) => {
    event.target.classList.add("was-validated");
    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData();
    formData.append("username", allValues.username.trim());
    formData.append('password', allValues.password);
    const data = await Caller(check_is_logged_in, formData, POST, false, true)
      .catch(error => { alert(JSON.stringify(error.message)); })

    if (data.success == true) {
      setLoginData(data);

      if (data.data.is_logged_in === false) {

        loginUser();

      } else {
        setAllValues((values) => ({
          ...values,
          openAlreadyLogin: true,
        }));
      }

    } else {
      setAllErrors((values) => ({ password: "Invalid Credentials." }))

    }

  }

  const loginUserWithoutSettingPwd = async (event) => {
    navigate("/dashboard", { state: { message: loginData } });
  }

  const openPasswordResetPopup = () => {
    setAllValues((values) => ({
      ...values,
      openCreatePwd: true,
    }));
  }

  const clickYesBtn = () => {
    setAllValues((values) => ({
      ...values,
      openAlreadyLogin: false,
    }));
    loginUser();

  }

  const clickNoBtn = () => {
    setAllValues((values) => ({
      ...values,
      openAlreadyLogin: false,
    }));
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    const baseURL = `${url.protocol}//${url.host}/`;
    // console.log('Base URL:', baseURL);
    setBaseUrl(baseURL);

  }, [location]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <BasicLayout image={bgImage}>
      <Dialog
        open={allValues.setOpen}
        onClose={handleClose}
        maxWidth='md'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">Forgot Password</MDTypography>
            <IconButton
              style={{ color: "white", cursor: "pointer" }}
              sx={{
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </MDBox></DialogTitle>
        <DialogContent >
          <ResetPasswordLink close={handleClose} />

        </DialogContent>
      </Dialog>

      {/* Password reset popup */}

      <Dialog
        open={allValues.openCreatePwd}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">Change Password</MDTypography>
          </MDBox></DialogTitle>

        <DialogContent p={3}>
          Do you want to continue with same password, or reset password.
        </DialogContent>
        <DialogActions
          sx={{
            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
            marginTop: "3%",
            width: "100%",
            borderRadius: '0.5rem',
            '.action-button': { width: 160 }
          }}
        >
          <MDButton color='success' className="action-button" onClick={() => navigate("/authentication/create-password", { state: { message: loginData } })}>Reset Password</MDButton>
          <MDButton color='warning' className="action-button" onClick={loginUserWithoutSettingPwd}>Continue</MDButton>
        </DialogActions>
      </Dialog>



      {/* already login popup */}
      <Dialog
        // open={allValues.openCreatePwd}
        open={allValues.openAlreadyLogin}
        onClose={handleClose}
        maxWidth='sm'
        sx={{
          '.MuiDialogContent-root': { pl: 5, pr: 5, pb: 5, pt: 2 }
        }}
      >
        <DialogTitle
        ><MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        // textAlign="center"
        >
            <MDTypography variant="h4" color="white">Already Logged-In</MDTypography>
          </MDBox></DialogTitle>

        <DialogContent p={3}>
          ALERT: You may be already logged in the application from another device or didn't log out correctly from the application before ! Do you want a fresh stable login and continue in the current device ?
        </DialogContent>
        <DialogActions
          sx={{
            background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
            marginTop: "3%",
            width: "100%",
            borderRadius: '0.5rem',
            '.action-button': { width: 160 }
          }}
        >
          {/* <MDButton color='error' className="action-button" onClick={() => navigate("/authentication/sign-in")}>No</MDButton> */}
          <MDButton color='error' className="action-button" onClick={clickNoBtn}>No</MDButton>
          <MDButton color='success' className="action-button" onClick={clickYesBtn}>Yes</MDButton>

        </DialogActions>
      </Dialog>

      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            WELCOME
          </MDTypography>
        </MDBox>
        <Grid container justifyContent="center" sx={{ mt: 1, mb: 1 }}>
          {/* <img className="logo" src={fd_logo} alt="" /> */}
          {/* <img className="logo" src={arp_logo} alt="" /> */}
          {/* {baseUrl === 'https://crm.firstdemat.com/' && <img className="logo" src={fd_logo} alt="fd_logo" />} */}
          {/* {baseUrl === 'https://crm.apnaresearchplus.com/' && <img className="logo" src={arp_logo} alt="arp_logo" />} */}
          {baseUrl === 'https://crm.apnaresearchplus.com/' ? <img className="logo" src={arp_logo} alt="arp_logo" /> : <img className="logo" src={fd_logo} alt="fd_logo" />}
          {/* {baseUrl === 'http://localhost:3000/' && <img className="logo" src={fd_logo} alt="fd_logo" />} */}
        </Grid>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={checkLoggedIn} >
            <MDBox mb={2}>
              <MDInput type="text" value={allValues.username} InputLabelProps={{
                shrink: true,
              }} onChange={handleChange} label="Email or Mobile" fullWidth required />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" value={allValues.password} InputLabelProps={{
                shrink: true,
              }} label="Password" onChange={handleChange1} fullWidth required />
            </MDBox>
            <div style={{ color: "red" }}>{errors.password}</div>
            <MDBox mt={4} mb={4}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>

            <MDBox mb={4} textAlign="center">
              <MDTypography
                variant="button"
                fontWeight="medium"
                color="info"
                textGradient
                onClick={openEmailInputPopup}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                Forgot Password
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>


    </BasicLayout>
  );
}

export default Login;
