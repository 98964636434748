/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from 'react'

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useSnackbar } from 'notistack';
import { DialogActions, FormControl, Grid, Icon, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import PageLayout from "examples/LayoutContainers/PageLayout";
import { createPassword } from "Apis/Auth/auth";
import Caller from "Apis/config/Caller";
import { POST } from "Apis/config/RequestType";
import { create_user_password } from "Apis/config/Url";

import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import { set_user_password } from "Apis/config/Url";
import Box from '@mui/material/Box';
import { borders } from '@mui/system';

const Cover = (props) => {
  const location = useLocation();
  const [loginData, setLoginData] = useState();
  const [linkUrl, setLinkUrl] = useState("");
  const [values, setAllValues] = useState({
    old_password: "",
    password: "",
    employee_user_id: "",
    is_password_set: ""
  });

  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);

      setLinkUrl(window.location.href)

      if (location.state) {
        setAllValues((state) => ({
          ...state,
          employee_user_id: location.state.message.data.employee_user_id,
          is_password_set: 1
        }));
        setLoginData(location.state.message);
      }
    }
  }, [])

  const { enqueueSnackbar } = useSnackbar();

  const textUpdate = (e) => {
    setAllValues((state) => ({
      ...state,
      [`${e.target.name}`]: e.target.value
    }));
  }

  const [errors, setAllErrors] = useState({
    old_password: '',
    password: "",
    email: "",
    phone: "",
    extension: '',
    not_match: "",
    profile_pic: ""
  });
  const navigate = useNavigate();
  const savePassword = async (event) => {

    event.preventDefault();
    if (values.password != values.old_password) {

      setAllErrors({ password: 'Password Not matching' });
      setAllErrors({ old_password: 'Password Not matching' });
    }
    const formData = new FormData();
    formData.append('password', values.password);
    // formData.append('url', window.location.href);
    formData.append('url', linkUrl);
    if (location.state) {
      formData.append('employee_user_id', values.employee_user_id);
      formData.append('is_password_set', values.is_password_set);
    }

    await Caller(location.state ? set_user_password : create_user_password, formData, POST, false, true).then((res) => {
      if (res.success === true) {
        enqueueSnackbar('Password set successfully!', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });

        location.state ? navigate('/dashboard', { state: { message: loginData } }) : navigate('/authentication/sign-in');
      }
    });

    event.target.classList.add("was-validated");

    event.stopPropagation();

  };

  const allTrue = () => {
    if ((values.password.length >= "8")&&(values.password.match(/[A-Z]/))&&(values.password.match(/[a-z]/)&&(values.password.match(/\d+/g)&&values.password.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)))&&(values.password === values.old_password)&&(values.password !== "")) {
      colour6 = "#6ca62b";
      return false
    } else {
      return true
    }
  }
  
  let colour1 = "#f26522",
    colour2 = "#f26522",
    colour3 = "#f26522",
    colour4 = "#f26522",
    colour5 = "#f26522",
    colour6 = "#f26522";

  if (values.password.length >= "8") {
    colour1 = "#6ca62b";
  }
  if (values.password.match(/[A-Z]/)) {
    colour2 = "#6ca62b";
  }
  if (values.password.match(/[a-z]/)) {
    colour3 = "#6ca62b";
  }
  if (values.password.match(/\d+/g) && values.password.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)) {
    colour4 = "#6ca62b";
  }
  if (
    values.password === values.old_password &&
    values.password !== ""
  ) {
    colour5 = "#6ca62b";
  }


  return (

    <PageLayout >

      <Grid container spacing={2} style={{ marginTop: "100px" }}>
        <Grid xs={4}></Grid>
        <Grid xs={3}>

          <Card style={{ textAlign: "center" }}>

            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign="center"
            >

              <MDTypography display="block" variant="button" color="white" my={1}>
                Please enter your password to begin.
              </MDTypography>
            </MDBox>
            <MDBox pt={3} pb={3} >
              <MDBox component="form" role="form" onSubmit={savePassword}>
                <Grid >
                  <TextField
                    required
                    id="outlined-required"
                    label="Password"
                    name='password'
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password === "" ? '' : errors.password}
                    value={values.password}
                    // error={!!errors.option_value}
                    style={{ width: "90%", marginTop: 35 }}
                    // helperText={errors.option_value ? errors.option_value : ''}
                    onChange={textUpdate}
                  />
                </Grid>

                <Grid style={{ marginTop: "25px" }}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Confirm Password"
                    name='old_password'
                    type="password"
                    value={values.old_password}
                    // error={!!errors.option_value}
                    style={{ width: "90%" }}
                    // helperText={errors.option_value ? errors.option_value : ''}
                    onChange={textUpdate}
                  />
                </Grid>

                <MDBox mt={7} mb={1}>
                  <MDButton color='success' className="action-button" type="submit" disabled={allTrue()} >
                    Confirm password and submit
                  </MDButton>
                </MDBox>

              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid xs={1}></Grid>


        <Grid xs={4} style={{ marginTop: "100px", }}>

          <Box
            sx={{ width: 430, borderRadius: '0px', p: 2, borderColor: colour6 }}
            border={3}
          >
            <div className="col-md-3 ">
              <div className="outer-div-style-p">

                <p style={{ color: colour3, fontSize: "18px" }}>
                  <OfflinePinIcon></OfflinePinIcon>
                  At least 8 characters
                </p>
                <p style={{ color: colour2, fontSize: "18px" }}>
                  <OfflinePinIcon></OfflinePinIcon>
                  At least 1 uppercase letter
                </p>
                <p style={{ color: colour3, fontSize: "18px" }}>
                  <OfflinePinIcon></OfflinePinIcon>
                  At least 1 lowercase letter
                </p>
                <p style={{ color: colour4, fontSize: "18px" }}>
                  <OfflinePinIcon></OfflinePinIcon>
                  At least 1 number and 1 special character
                </p>
                <p style={{ color: colour5, fontSize: "18px" }}>
                  <OfflinePinIcon></OfflinePinIcon>

                  Password and Confirm Password must match
                </p>
              </div>
            </div>
          </Box>
        </Grid>

      </Grid>

    </PageLayout>

  );
}

export default Cover;
